/* Color Variables */
$color-brand: #4931DD;
$color-brand-50-transparent: rgba(54, 82, 227, 0.5);
$color-brand-hover: #233FCD;
$color-error-easy: rgba(242, 202, 202, 0.72);
$color-error-juice: #FE2E56;
$color-attention-easy: rgba(255, 215, 96, 0.24);
$color-attention-juice: #FFD760;
$color-success-easy: rgba(75, 222, 151, 0.1);
$color-success-juice: #4BDE97;
$color-link: #0086FF;
$color-link-hover: #0068C6;
$color-submarine-easy: #FAF8F3;
$color-submarine-juice: #FFCC81;
$color-submarine-juice2: #FFB648;
$color-white: #fff;
$color-gray: #90A0B7;
$color-dark: #000;
$color-tabelgray: #707683;
$color-hovered-gray-12: #F1F2F3;
$color-gray-500: #71717A;
$color-table-black: #323C47;
$color-text-color: #181A1F;
$color-text-black: #192A3E;
$color-text-text-darkblue: #334D6E;
$color-line: #E6EAF0;
$color-green: #4CDE98;
$color-dark-dark-1: #B2BCCA;
$color-dark-dark-2: #828282;
$color-dark-dark-3: #4F4F4F;

$color-blue-blue-2: #1660CF;

$color-grey-grey-1: #F6F6F6;
$color-grey-grey-5: #F9FAFB;
$primary-blue: #4931DD;
$primary-black: #23252A;

$color-text-table-gray: #90A0B7;
$color-dark-tone-100: #242533;
$color-soft-1: #EDECE7;
$color-soft-5: #8C8AA6;
$color-vibrant-orange: #E84125;
/* Color Variables End */
